// @ts-ignore
export const onlyLetters = /\p{L}+/u;
export const onlyNumbers = /^[0-9]*$/;
export const countryCode = /^\+\d{1,3}$/;
export const passwordRules = /^(?=.{12,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/;
export const postalCodeCA = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;
export const postalCodeUS = /^\d{5}(-\d{4})?$/;
export const noConsequentHyphens = /^(?!.*--).*$/;
export const addressUSHD = /^(?!.*\b(p[\s.]*o)\b).*$/i;

//Before Headless each market had its own formats for orders. This validators cover these requirements
export const MYOrderNumber = /^(#?MY-\d{11}|MY\d{9}|8700-\d{6}|8700R-d{6})$/;
export const SGOrderNumber = /^(#?(DE|EC)\d{8}|SG\d{9}|(3800|3800R)-(\d{6}|\d{9}))$/;
export const ESOrderNumber = /^(#?ES\d{8}|3600-\d{6}|RO-36\d{9})$/;
export const EEOrderNumber = /^(#?EE-\d{11}|EE\d{8}|SO-760\d{7}|RO-76\d{9})$/;
export const INOrderNumber = /^(#?DE\d{8}|EC\d{8}|IN\d{9}|3820-\d{6}|SO-38\d{9})$/;
export const KROrderNumber = /^(#?DE\d{8}|EC\d{8}|KR\d{9}|6800-\d{6}|SO-68\d{9}|6800R-\d{6})$/;
export const JPOrderNumber = /^(#?(DE|EC)\d{8}|JP\d{9}|SO-(\d{9,10})|6100-\d{5}|6100R-\d{7})$/;
export const AUOrderNumber = /^(#?(DE|EC)\d{8}|AU\d{9}|ST200-\d{6}|ST-\d{9}|200RO-\d{6,7})$/;
export const CAOrderNumber = /^(#?(DE|EC)\d{8}|CA\d{9}|(S8|R7|R8)\d{8})$/;
export const USOrderNumber = /^(#?(DE|EC)\d{8}|US\d{9}|(S7|R7)\d{8})$/;
