import { AnalyticsClient } from "@lib/analytics/AnalyticsClient";
import { useAuth } from "@lib/auth/useAuth";
import { useGetMyProfile } from "@store/customerHooks";
import { useStore } from "@store/index";
import type { StoreState } from "@store/index";
import { useRouter } from "next/router";
import type { ReactNode } from "react";
import { useEffect } from "react";

export const AnalyticsProvider = ({
    pageType,
    children,
}: {
    pageType: string;
    children?: ReactNode;
}) => {
    const getMyProfile = useGetMyProfile();
    const {
        data: { me },
    } = useStore<StoreState>();
    const { session, isAnonymous, isAuthenticated } = useAuth();
    const { events: routerEvents, locale } = useRouter();

    useEffect(() => {
        AnalyticsClient.init(locale, me, session);
    }, [me, locale, session]);

    useEffect(() => {
        if (isAuthenticated) {
            getMyProfile({});
        }
    }, [isAuthenticated, getMyProfile]);

    useEffect(() => {
        let analyticsTimeout;

        const handleRouteChange = () => {
            analyticsTimeout = setTimeout(() => {
                AnalyticsClient.pageView(window.location.href, pageType);
                AnalyticsClient.cartContents();
            }, 1000);
        };

        routerEvents.on("routeChangeComplete", handleRouteChange);

        return () => {
            clearTimeout(analyticsTimeout);
            routerEvents.off("routeChangeComplete", handleRouteChange);
        };
    }, [routerEvents, locale, pageType]);

    useEffect(() => {
        AnalyticsClient.pageView(window.location.href, pageType);
    }, []);

    useEffect(() => {
        const timerId = setTimeout(() => {
            AnalyticsClient.updateAnalyticsClientSettings(me, session);
            AnalyticsClient.saveUserData(me, session, isAuthenticated, pageType);
            AnalyticsClient.cartContents();
        }, 2000);
        return () => clearTimeout(timerId);
    }, [session?.user, isAuthenticated, isAnonymous, me?.cartInfo?.cart, me?.customer]);

    return <>{children}</>;
};
